export default function useGoogleMap(nearbyFilters, placeMarkers, nearbyList, google, map, serviceAnchor, labelAnchor) {
    const _getIconForType = (types) => {
        if (types.includes('restaurant')) {
            return '/img/common/restaurant_icon.svg';
        }
        if (types.includes('cafe') || types.includes('coffee')) {
            return '/img/common/cafe_icon.svg';
        }
        if (types.includes('parking')) {
            return '/img/common/park_icon.svg';
        }
        if (types.includes('transit_station')) {
            return '/img/common/bus_icon.svg';
        }
        return '';
    };
    const onNearbyFilterClick = (filterName) => {
        nearbyFilters[filterName] = !nearbyFilters[filterName];
        if (placeMarkers.value[filterName] &&
            placeMarkers.value[filterName].length > 0) {
            placeMarkers.value[filterName].map(marker => marker.setVisible(nearbyFilters[filterName]));
        }
    };
    const getNearbyPlaces = (results, type) => {
        /*
        Workaround to remove deprecated attribute `opening_hours.open_now` which causes a lot of console errors.
        https://developers.google.com/maps/documentation/javascript/place_field_js_migration
         */
        results.map(function (result) {
            delete result.opening_hours;
            delete result.permanently_closed;
            return result;
        });
        // nearbySearch
        let filteredResult = [...results];
        if (type === 'restaurant') {
            filteredResult = results.filter(item => !item.types.includes('cafe'));
            nearbyList.restaurant = filteredResult;
        }
        if (type === 'cafe') {
            nearbyList.cafe = filteredResult;
        }
        if (type === 'parking') {
            nearbyList.parking = filteredResult;
        }
        if (type === 'transit_station') {
            nearbyList.transit_station = filteredResult;
        }
        const MarkerWithLabel = require('markerwithlabel')(google.value.maps);
        placeMarkers.value[type] = nearbyList[type].map(place => new MarkerWithLabel({
            map: map.value,
            icon: {
                url: _getIconForType(place.types),
                anchor: serviceAnchor.value
            },
            position: place.geometry.location,
            labelContent: `<span style="
                      width: 70px;
                      word-break: break-word;
                      display: block;
                      text-shadow: 0 1px white, 0 -1px white, 1px 0 white, -1px 0 white, 1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white;
                      font-weight: bold;
                      font-size: 12px;
                      font-family: Roboto, sans-serif;">${place.name}</span>`,
            labelClass: 'marker-name', // the CSS class for the label
            labelAnchor: labelAnchor.value,
            visible: nearbyFilters[type],
            zIndex: 1000
        }));
    };
    const getMapOverlay = (BasicOverlay) => {
        if (!BasicOverlay)
            return undefined;
        class CustomOverlay extends BasicOverlay {
            left;
            top;
            venueId;
            constructor(position, map, onPositionUpdated, venueId) {
                super();
                this.position = position;
                this.map = map;
                this.setMap(map);
                this.onPositionUpdated = onPositionUpdated;
                this.venueId = venueId;
            }
            /**
             * Draw the overlay on the map.
             * This method should be implemented by any subclass of CustomOverlay.
             * It will be called once after the overlay has been created.
             */
            draw() {
                const overlayProjection = this.getProjection();
                const position = overlayProjection.fromLatLngToDivPixel(this.position);
                const mapBounds = this.map.getBounds();
                const isPositionInsideMapBounds = mapBounds.contains(this.position);
                this.left = `${position.x - 27}px`;
                this.top = `${position.y - 40}px`;
                // Call the callback function with the new values
                if (typeof this.onPositionUpdated === 'function') {
                    this.onPositionUpdated(this.left, this.top, this.venueId, isPositionInsideMapBounds);
                }
            }
        }
        return CustomOverlay;
    };
    return {
        getMapOverlay,
        getNearbyPlaces,
        onNearbyFilterClick
    };
}
